import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import Stundenplan, { StundenplanItem } from '../components/Stundenplan';
import { transformTimeToDate } from '../utils/transformTimeToDate';

const STUNDENPLAN_WINTERTHUR_QUERY = graphql`
  {
    allContentfulStundenplanWinterthur {
      nodes {
        id
        name
        von
        bis
        tag
        kurs {
          titel
          kategorie
        }
        teacher {
          name
        }
      }
    }
  }
`;

const StundenplanWinterthurPage: FC = () => {
  const data = useStaticQuery(STUNDENPLAN_WINTERTHUR_QUERY);
  const stundenplanItems: StundenplanItem[] = data.allContentfulStundenplanWinterthur.nodes.map(n => ({
    name: n.name,
    day: n.tag.toLowerCase(),
    teacher: n.teacher ? n.teacher.name.split(" ")[0] : '',
    startTime: transformTimeToDate(n.von),
    endTime: transformTimeToDate(n.bis),
    course: n.kurs ? n.kurs.titel : '',
    kategorie: n.kurs ? n.kurs.kategorie : '',
    displayCourseName: n.name,
    teacherFullName: n.teacher ? n.teacher.name : '',
  }));


  return (
    <Layout location={Routes.STUNDENPLAN_WINTERTHUR}>
      <SEO title="News" />
      <div className="mt-8">
        <Title className="mb-4 text-center">Stundenplan Winterthur</Title>
        <div className="transition-all transition-250 hover:shadow-xl mt-8 shadow-lg rounded lg:px-4">
          <Stundenplan items={stundenplanItems} />
        </div>
      </div>
    </Layout>
  );
};

export default StundenplanWinterthurPage;
